declare module "vue-router" {
  interface RouteMeta {
    allowedRoles?: Set<role> | role[];
  }
}

declare module "nuxt/schema" {
  interface PageMeta {
    allowedRoles?: Set<role> | role[];
  }
}

/**
 * Named route middleware to check if a user is authorised.
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return;

  const user = useUser();
  await user.ready;

  if (!user.loggedIn)
    return navigateTo({
      name: "sign-in",
      query: { redirect: to.fullPath },
    });

  let allowedRoles = to.meta.allowedRoles;
  if (Array.isArray(allowedRoles)) allowedRoles = new Set(allowedRoles);
  if (user.isAdmin || allowedRoles === undefined || allowedRoles.has(user.role)) return;
  return abortNavigation(
    createError({
      statusCode: 401,
      statusMessage: "You are not authorized to view this page.",
    })
  );
});
